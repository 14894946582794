// Styles

// Main styles

@import './app.scss';

.meta-custom {
    background-color: #ededed;
    padding: 20px;
    box-shadow: 0 0 15px #cbcbcb;
}

.meta-title-custom {
    height: 77px;
    overflow: hidden;
}

.line-custom {
    height: 30px;
    overflow: hidden;
}

.loading-container {
    width: 100%;
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
}

.ant-collapse-header {
    font-weight: bolder;
}

.material-item {
    cursor: pointer;
    color: #bb2327;
    padding: 17px 34px;
    margin: -17px;

    &:hover {
        color: white;
        background-color: #bb2327;
        box-shadow: 0 1px 4px 0 rgba(49, 52, 53, 0.12);
    }
}

.input-common {
    line-height: 40px;
    padding: 0 16px;
    border-radius: 8px;
}

.btn-common {
    height: 45px;
    border-radius: 8px;

    span {
        font-size: 14px;
    }
}

@media (max-width: 575px) {
    .meta-title-custom {
        height: 130px;
    }
}
@media (min-width: 576px) and (max-width: 820px) {
    .meta-title-custom {
        height: 100px;
    }
}

.ant-collapse-item-custom {
    border: 1px solid #d9d9d9;
    margin-bottom: 15px;
    background-color: #ededed;
}
.md-editor-content .md-editor-preview, .md-editor-content .md-editor-html {
    word-break: normal !important;
  }