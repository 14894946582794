.footer_wrapper {
  width: 100%;
  background-color: #181821;
  color: #a9b3bb;
  overflow: hidden;
  padding: 68px 0 20px;
  @media screen and (max-width: 575px) {
    padding-top: 32px;
  }
}

.footer_wrapper a {
  color: #a9b3bb;

}

.section_wrapper {
  width: 80%;
  margin: 0 auto;
  @media screen and (max-width: 575px) {
    width: 95%;
  }
}

.footer-column {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
}

.footer-column-top {
  display: flex;
  align-items: center;
}

.footer_top-logo {
  width: 120px;
  margin-right: 10px;
  object-fit: cover;
  object-position: center;
}

.footer_top-slogan {
  margin: 0;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 700;
}

.footer-contact-list {
  color: #a9b3bb;
  font-size: 0.8rem;
  line-height: 1.8rem;
  margin-top: 18px;

  a {
    font-size: 0.8rem;
  }
}

.footer-contact-list a:hover {
  color: rgba(169, 179, 187, 0.8);
}

.footer-heading {
  padding-top: 16px;
  color: #fff;
  font-size: 1.2rem;
  line-height: 24px;
  //text-transform: uppercase;
}

.footer-list {
  list-style-type: none;
  margin-top: 12px;
  padding: 0;
}

.footer-list li {
  margin-bottom: 6px;
  font-size: 0.8rem;

  a {
    font-size: 0.8rem;
  }
}

.footer-bottom {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;

  .footer-bottom-text {
    font-size: 0.8rem;
  }

  svg {
    font-size: 32px;
    margin-right: 14px;
  }

}


.feedback__icon{
  position: fixed;
  bottom: 5%;
  right: 2%;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-name: tada;
  color: rgb(187, 35, 39);
  z-index: 1000;
  & svg {
    font-size: 35px;
  }
}

@keyframes tada {
  0% {
    transform: scale3d(1,1,1)
  }

  10%,20% {
    transform: scale3d(.9,.9,.9) rotate3d(0,0,1,-3deg)
  }

  30%,50%,70%,90% {
    transform: scale3d(1.1,1.1,1.1) rotate3d(0,0,1,3deg)
  }

  40%,60%,80% {
    transform: scale3d(1.1,1.1,1.1) rotate3d(0,0,1,-3deg)
  }

  100% {
    transform: scale3d(1,1,1)
  }
}
