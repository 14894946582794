.logo {
  display: flex;
  align-items: center;
}

.top-header {
  @media screen and (max-width: 991px) {
    padding: 0;
  }
  @media screen and (min-width: 992px) {
    z-index: 1;
    width: 100%;
    padding: 0 10% !important;
  }
}

.header__spn-border {
  padding:  0 .3rem;

  &:hover {
    border-bottom: 1px solid crimson;
  }
}
.ant-menu-item , .ant-menu-overflow-item{
  display: flex !important;
  align-items: center;
}

