$background: #fff;
$top-nav: #fff;
$text: rgb(0, 0, 0);
$box-shadow: rgba(49, 52, 53, 0.12);
$grey: rgb(128, 128, 128);
$white: #fff;
$black: rgb(0, 0, 0);
$blue: #0f668d;
$grey2: #c8c5c5;
$orange: rgb(255, 165, 0);
$text-black: #263238;
$text-black-2: #455A64;
$border: #DDDDDD;
$color-hover: #BB2327;
@function breakpoints($breakpoint) {
  @return map-get($grid-breakpoints, $breakpoint);
}

$grid-breakpoints: (xxs: 0px,
  xs: 481px,
  sm: 768px,
  smplus: 769px,
  md: 992px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1450px,
  xxxl: 1650px,
);


@mixin min-xxxl {
  @media screen and (min-width: #{breakpoints(xxxl)}) {
    @content;
  }
}

@mixin min-xxl {
  @media screen and (min-width: #{breakpoints(xxl)}) {
    @content;
  }
}

@mixin min-xl {
  @media screen and (min-width: #{breakpoints(xl)}) {
    @content;
  }
}

@mixin min-lg {
  @media screen and (min-width: #{breakpoints(lg)}) {
    @content;
  }
}

@mixin min-sm {
  @media (min-width: #{breakpoints(md)}) {
    @content;
  }
}

@mixin min-sm {
  @media (min-width: #{breakpoints(sm)}) {
    @content;
  }
}

@mixin min-smplus {
  @media (min-width: #{breakpoints(sm)} + 1px) {
    @content;
  }
}

@mixin min-xs {
  @media screen and (min-width: #{breakpoints(xs)}) {
    @content;
  }
}

@mixin min-xxs {
  @media screen and (max-width: #{breakpoints(xxs)}) {
    @content;
  }
}

@mixin center-xxl-xl {
  @media screen and (max-width: #{breakpoints(xxxl)}) and (min-width: #{breakpoints(xl)}) {
    @content;
  }
}
