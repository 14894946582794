.top-menu{
  display: flex;
  .logo{
    display: flex;
    align-items: center;
    font-weight: 900;
    padding: 0 20px;
  }

  .ant-menu-root{
    width: 100%;
  }
}