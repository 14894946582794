@import '../custom/variables';
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,200;8..144,300;8..144,400;8..144,500;8..144,600;8..144,700&display=swap');

html,
body * {
  font-family: Roboto Flex;
  font-size: 16px;
  scroll-behavior: smooth;
}

// custom scrollbar body - hidden
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

body::-webkit-scrollbar {
  display: none;
}

.custom-scroll-bar {
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ced4da;
    border-radius: 4px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.ant-layout {
  background: $background;
}

.ant-layout-header .ant-menu {
  border: none;
}

.table-custom .ant-table-content {
  box-shadow: 0 1px 4px 0 $box-shadow;
}

.color {
  &__grey {
    color: $grey;
  }

  &__white {
    color: $white;
  }

  &__grey-bold {
    color: $grey2;
  }
}

.text {
  &__14B {
    font-size: 14px;
    font-weight: bold;
    color: $text;
    margin: 0px;
  }

  &__40B {
    font-size: 40px;
    font-weight: bold;
    color: black;
    margin: 0px;
  }

  &__16 {
    font-size: 16px;
    color: black;
  }

  &__40B_linear_gradient {
    font-size: 50px;
    //     font-weight: bold;
    //     // color: black;
    //     margin: 0px;
    //     animation: color-change 4s infinite;
    // }
    // @keyframes color-change {
    //     0% {
    //         color: black;
    //     }
    //     20% {
    //         color: pink;
    //     }
    //     40% {
    //         color: blue;
    //     }
    //     60% {
    //         color: green;
    //     }
    //     80% {
    //         color: yellow;
    //     }
    //     100% {
    //         color: grey;
    //     }
    // }
    transform: translate(0%, -50%);
    text-transform: uppercase;
    font-family: verdana;
    font-weight: 700;
    margin-bottom: 0;
    color: white;
    text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191, 1px 4px 1px #919191,
      1px 5px 1px #919191, 1px 6px 1px #919191, 1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
      1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4), 1px 22px 10px rgba(16, 16, 16, 0.2),
      1px 25px 35px rgba(16, 16, 16, 0.2), 1px 30px 60px rgba(16, 16, 16, 0.4);
  }

  &__40B_linear_gradient_welcome {
    margin-bottom: 0;
    font-weight: 700;
    text-align: center;
    font-size: 1.5rem;
    font-family: Hack, sans-serif;
    text-transform: uppercase;
    background: linear-gradient(90deg, #000, #fff, #000);
    letter-spacing: 5px;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 80%;
    animation: shine 5s linear infinite;
  }

  @keyframes shine {
    0% {
      background-position-x: -500%;
    }

    100% {
      background-position-x: 500%;
    }
  }
}

.h-60 {
  height: 60px;
}

.container-roadmap {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container-roadmap {
    width: 750px;
  }

}

@media (min-width: 992px) {
  .container-roadmap {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container-roadmap {
    width: 1170px;
  }
}

@media (min-width: 1500px) {
  .container-roadmap {
    width: 1290px;
  }
}

.ant-layout-header {
  position: fixed;
  left: 0;
  top: 0;
}

.ant-layout-content {
  margin-top: 50px;

}

.text-right {
  text-align: right;
}

.disable-event {
  pointer-events: none;
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}
